<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoadingInitData"/>

    <div class="vx-row mb-3" v-if="currentProgress">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card>
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="General" icon-pack="feather">
              <TabGeneral :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Progress Pekerjaan" icon-pack="feather">
              <TabProgress :isActive="activeTab === 1" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>
      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="SPP-PROGRESS"
            :id-ref="$route.params.idProgressUnit"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <SpkProgressApprovalSigning :isActive.sync="modalSigning.active"
                             :idProgressUnit="modalSigning.idProgressUnit"
                             :status="modalSigning.status"
                             @success="onSigned"/>
  </div>
</template>

<script>
import ProgressUnitRepository from '@/repositories/proyek/progress-unit-repository'
import moduleSpkProgressApproval from '@/store/modules/approvals/spk-progress-approval/spk-progress-approval.store'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'SpkProgressApprovalShow',
  components: {
    TabGeneral: () => import('@/views/pages/approvals/spk-progress-approval/parts/TabGeneral'),
    TabProgress: () => import('@/views/pages/approvals/spk-progress-approval/parts/TabProgress'),
    SpkProgressApprovalSigning: () => import('@/views/pages/approvals/spk-progress-approval/SpkProgressApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        spk: {},
        pekerjaan: {},
        progress: []
      },
      modalSigning: {
        active: false,
        idProgressUnit: this.$route.params.idProgressUnit,
        status: null
      }
    }
  },
  computed: {
    currentProgress () {
      const idProgressUnit = this.$route.params.idProgressUnit.toString()
      return _.find(this.initData.progress, item => item.id.toString() === idProgressUnit)
    },
    activeTab: {
      get () {
        return this.$store.state.approvals.spkProgressApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/spkProgressApproval/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      dataTabGeneral: state => state.approvals.spkProgressApproval.tabGeneral,
      dataTabProgress: state => state.approvals.spkProgressApproval.tabProgress
    })
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idProgressUnit = this.$route.params.idProgressUnit
      ProgressUnitRepository.showProgressPekerjaan(idProgressUnit)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'spkProgressApproval'], moduleSpkProgressApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/spkProgressApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'spkProgressApproval'])
  }
}
</script>
